import React from 'react';
import './footerbar.css';

const Footerbar = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div style={{overflow: "hidden", overflowY: "hidden", overflowX:"hidden", scrollbarColor: "transparent"}}>
      <div className="footerbar">
        <div className="leftButton">
          <a href="/sitemap/" className='optimanormal-footerbar'>Sitemap</a>
          <a href="/privacy-policy/" className="optimanormal-footerbar">Privacy Policy</a>
          <a href="/cookie-policy/" className="optimanormal-footerbar">Cookie Policy</a>
        </div>
      </div>

      <div className='footer-pc'>
        <div className="footer">
        <div className="container">
          <p className="footer-text">
            <a style={{marginRight: "100px"}} className="footer-text" href='https://www.3cs.lk/'>
              Copyright &copy; {currentYear}  Lalan Group. site by 3CS.
            </a>
          </p>
        </div>
        </div>
      </div>

      <div className='footer-tab'>
        <div className="white-footer">
        <div className="container">
          <p className="footer-text">
            <center>
            <a className="footer-text" href='https://www.3cs.lk/'>
              Copyright &copy; {currentYear}  Lalan Group. site by 3CS.
            </a>
            </center>
          </p>
        </div>
        </div>
      </div>

      <div className='footer-mobile'>
        <div className="footer">
        <div className="container">
          <p className="footer-text">
            <a style={{marginRight: "100px"}} className="footer-text" href='https://www.3cs.lk/'>
              Copyright &copy; {currentYear}  Lalan Group. site by 3CS.
            </a>
          </p>
        </div>
        </div>
      </div>


    </div>
  );
};
export default Footerbar;