import React from "react";
import "./packaingMaincontent.css";
import {
  Parallax,
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";

const PackaingMaincontent = () => {
  return (
    <ParallaxProvider>
      <div className="responsivePackaging">
        <br />

        <div className="first-para">
          <div className="packaging-pc">
            <ParallaxBanner style={{ aspectRatio: "2 / 1", width: "100%" }}>
              <ParallaxBannerLayer
                image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/packaging/tile-01-a.webp"
                speed={7}
              />
            </ParallaxBanner>
          </div>
          <div className="packaging-mobile">
            <ParallaxBanner style={{ aspectRatio: "auto 360 / 540" }}>
              <ParallaxBannerLayer
                image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/packaging/tile-01-a.webp"
                speed={10}
              />
            </ParallaxBanner>
          </div>

          <p className="parapara">
            The establishment of Lalan Marketing Services in 1983, entering the
            packaging industry, saw the first diversification of Lalan Group
            away from its core rubber trading business. Further exploration and
            expansion into packaging continued with the creation of Lalan
            Printing and Packaging, LN Paper Converters, and LN Polysacks. By
            the late 2000's Lalan consolidated its position as a noteworthy
            solution provider in Sri Lanka's packaging industry.
            <br />
            Driven by our dynamic and quality-oriented teams, these modern
            manufacturing facilities employ state-of-the-art automated machinery
            which assist us to further our active transition into Industry 4.0.
            We understand the importance of quality and on-time delivery, which
            enables us to continuously serve better to an array of export
            orientated organisations, local manufactures, and distributors. Our
            presence spans across FMCG, Industrial, Logistics, Poultry, Animal
            Feed, Seafood, Agricultural, Medical, Pharmaceutical, and Apparel
            sectors.
            <br />
            Encouraged by our group values of Excellence in Quality, Spirit in
            Innovation, and Sustainability in Growth, we deliver peace of mind
            to all stakeholders.
            <br />
          </p>
        </div>

        <div className="first-para">
          <p className="address1">
            81 A, Temple Road, <br />
            Sri Jayawardenepura Kotte, <br />
            Sri Lanka. <br />
            info.packaging@lalangroup.com (+94) 11 434 7700 <br />
            (+94) 11 461 2106/102 <br />
            <br />
          </p>
        </div>

        <div style={{ marginTop: "-200px" }}>
          <div className="two-column">
            <div className="colum-2">
              {/* Column 1 */}
              <Parallax speed={30}>
                <div className="packaging-pc">
                  <ParallaxBanner style={{ aspectRatio: "2 / 2" }}>
                    <ParallaxBannerLayer
                      image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/packaging/tile-06.webp"
                      speed={10}
                    />
                  </ParallaxBanner>
                </div>
                <div className="packaging-mobile">
                  <ParallaxBanner style={{ aspectRatio: "2 / 2.8" }}>
                    <ParallaxBannerLayer
                      image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/packaging/tile-06.webp"
                      speed={10}
                    />
                  </ParallaxBanner>
                </div>

                <br />
                <h1 className="topi">Lalan Printing and Packaging (Pvt) Ltd</h1>
                <p className="para">
                  Adhesive tapes (BOPP) <br />
                  The key product since inception in 1994, our range has
                  consistently improved whilst maintaining and adhering to our
                  stringent quality parameters, thereby sustaining the
                  confidence of all users. In-house coating, printing, and
                  slitting facilities, guarantees better quality assurance and
                  flexibility to our customers. <br />
                  Product : Clear/Tan/Coloured - printed / unprinted <br />
                  Production capacity : over 2 Million Sq m per month <br />
                  <br />
                  Strapping Band <br />
                  Upholding the same consumer confidence, an expansion into PP
                  strapping soon followed. Today, our range of PP strapping
                  dominates the market, thus making LALAN the market leader in
                  Sri Lanka. <br />
                  Product : PP Strapping - hand / semi-auto / full-auto <br />
                  Production capacity : over 1,200 MT per annum <br />
                  <br />
                  Computer Forms (Continuous sheets) <br />
                  We offer customers a selection between NCR (no carbon
                  required) and IWB (International White Bond) unprinted and
                  printed continuous computer forms. We cater to an array of
                  State Departments, State Utilities, Financial Institutions,
                  Healthcare Institutions, Exporters etc. <br />
                  Production capacity : over 1,000 MT per annum <br />
                  <br />
                  Polythene <br />
                  As the demand for polythene as a cost-effective substitute
                  grew, the addition of a polythene plant followed. The material
                  is extruded, printed, cut, and sealed in-house to specific
                  customer requirements. <br />
                  Products : Bags and Roll form <br />
                  Production capacity : over 350 MT per annum <br />
                  <br />
                </p>

                <p className="address">
                  Lalan Printing and Packaging (Private) Limited. <br />
                  Lalan Warana Complex, <br />
                  Gorakadeniya Road, <br />
                  Uduthuththiripitiya, <br />
                  Yakkala, <br />
                  Sri Lanka. <br />
                  <br />
                </p>
              </Parallax>
            </div>

            <div className="colum-2">
              {/* Column 2 */}
              <div style={{ marginTop: "700px" }}>
                <Parallax speed={-40}>
                  <div className="packaging-pc">
                    <ParallaxBanner style={{ aspectRatio: "2 / 2" }}>
                      <ParallaxBannerLayer
                        image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/packaging/tile-03.webp"
                        speed={10}
                      />
                    </ParallaxBanner>
                  </div>
                  <div className="packaging-mobile">
                    <ParallaxBanner style={{ aspectRatio: "2 / 2.8" }}>
                      <ParallaxBannerLayer
                        image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/packaging/tile-03.webp"
                        speed={10}
                      />
                    </ParallaxBanner>
                  </div>

                  <br />
                  <h1 className="topi">LN Paper Converters (Pvt) Ltd</h1>
                  <p className="para">
                    Since 2014, we have manufactured Corrugated Cartons and
                    Sheets for Export orientated businesses, FMCG brands, and
                    other local producers. A capacity expansion in 2021, enabled
                    entry into new market segments and other value-added
                    products. <br />
                    <br />
                    Products: <br />
                    Regular Slotted Cartons (RSC) <br />
                    Die Cut / Laminated / Telescopic Cartons <br />
                    Pads / Partitions / Dividers <br />
                    Sheets and Custom designs <br />
                    Production capacity : over 10,000 MT per annum
                    <br />
                    <br />
                  </p>

                  <p className="address">
                    LN Paper Converters (Private) Limited <br />
                    Lalan Unagahadeniya Complex, <br />
                    Kamburagallawatta, <br />
                    Unagahadeniya, <br />
                    Ruggahawila, <br />
                    Sri Lanka. <br />
                    <br />
                  </p>
                </Parallax>
              </div>
            </div>
          </div>
        </div>

        {/* ........ 2 ....................................................... */}

        <div style={{ marginTop: "200px" }}>
          <div className="two-column">
            <div className="colum-2">
              {/* Column 1 */}
              <div style={{ marginTop: "70px" }}>
                <div className="packaging-pc">
                  <ParallaxBanner style={{ aspectRatio: "2 / 1.3" }}>
                    <ParallaxBannerLayer
                      image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/packaging/tile-04.webp"
                      speed={10}
                    />
                  </ParallaxBanner>
                </div>
                <div className="packaging-mobile">
                  <ParallaxBanner style={{ aspectRatio: "2 / 2.8" }}>
                    <ParallaxBannerLayer
                      image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/packaging/tile-04.webp"
                      speed={10}
                    />
                  </ParallaxBanner>
                </div>
              </div>
              <br />
              <h1 className="topi">LN Polysacks (Pvt) Ltd</h1>
              <p className="para">
                Launched in 2009, with a handful of machines and an output of
                150MT per annum, we have grown rapidly year-on-year. 2020, saw a
                significant investment into capacity expansion and productivity
                enhancements which catapulted our position in the market.
                Boasting only top of the line Starlinger&reg; looms, we cater to
                industry leaders in animal feed, poultry, rice, and logistics,
                etc. Our teams work consistently on improvements to offer our
                customers the best products. <br />
                <br />
                Products : <br />
                Woven Polypropylene Sacks / Bags / Rolls (printed / unprinted)
                Non-Woven Sacks / Bags / Rolls (printed / unprinted) <br />
                Production capacity : over 1,500 MT per annum <br />
                <br />
              </p>

              <p className="address">
                LN Polysacks (Private) Limited <br />
                Galwarusawa Road, <br />
                Mahadeniya, <br />
                Oruwala, Athurugiriya, <br />
                Sri Lanka. <br />
                <br />
              </p>
            </div>

            <div className="colum-2">
              {/* Column 2 */}
              <div className="packaging-pc">
                <ParallaxBanner style={{ aspectRatio: "2 / 2" }}>
                  <ParallaxBannerLayer
                    image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/packaging/gal-05.webp"
                    speed={10}
                  />
                </ParallaxBanner>
              </div>
              <div className="packaging-mobile">
                <ParallaxBanner style={{ aspectRatio: "2 / 2.8" }}>
                  <ParallaxBannerLayer
                    image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/images/packaging/gal-05.webp"
                    speed={10}
                  />
                </ParallaxBanner>
              </div>

              <br />
              <h1 className="topi">Lalan Marketing Services</h1>
              <p className="para">
                In keeping with core group values, our research and development
                teams continuously work with local and global institutions and
                partners to develop more environmentally friendly and
                sustainable packaging options. <br />
                <br />
                Products :Stretch film and Shrink film
                <br />
                Production capacity : over 350 MT per annum <br />
                <br />
              </p>

              <p className="address">
                Lalan Marketing Services <br />
                Lalan Warana Complex, <br />
                Gorakadeniya Road, <br />
                Uduthuththiripitiya, <br />
                Yakkala, <br />
                Sri Lanka. <br />
                <br />
              </p>
            </div>
          </div>
        </div>

        <div className="packaging-pc">
          <center>
            <h1 className="bigHugeTitle">
              9 Sectors <br />
              of Integrated <br />
              <span
                style={{
                  color: "#46708a",
                  fontStyle: "italic",
                  fontWeight: "bold",
                }}
              >
                Expertise
              </span>
            </h1>
            <div style={{ marginTop: "100px", marginBottom: "200px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "200px",
                }}
              >
                <div style={{ width: "2px", background: "#46708a" }}></div>
              </div>
            </div>
          </center>
        </div>

        <div
          className="packaging-mobile"
          style={{ marginTop: "-200px", marginBottom: "400px" }}
        >
          <p className="text-max-packaging">
            9 Sectors of Integrated
            <span
              style={{
                color: "#46708a",
                fontStyle: "italic",
                fontWeight: "bold",
              }}
            >
              Expertise
            </span>
          </p>
        </div>

        <div></div>
      </div>
    </ParallaxProvider>
  );
};

export default PackaingMaincontent;
